import React from "react"

import SEO from "../components/seo"
import rond_color from "../assets/rond_color.svg"
import { Link } from "gatsby"

import Navigation from "../components/Navigation"


const Manifesto = () => (
    <div
        className="containerManifesto"
      >
        <div className=" container containerHome">
        <Navigation/>
        {/*<div className="rondTache row">
          <img src={rond} className="col-3 rond"/>
          <img src={tache} className="col-9 tache"/>
        </div>*/}
    <div>
    <SEO title="Plaiz - Manifesto - Plaiz, le réseau social de la mode"/>
    <div>
    <h1 className="p1">Manifesto</h1>

    <h4
      style={styles.backToHomeLink}
    >
    <p className="textChangeGame"><br/>
            <br/>
            <b><i>“Les gars, vous changez le game.”</i></b>
            <br/>
            <i>- un participant à la Plaiz Anti Fashion Week</i><br/>
            <br/>
            <br/>
            Pourquoi changer ? <br/>
            <br/>
            Tout allait bien alors pourquoi changer ? <br/>
            <br/>
            1/ Parce qu’on vit dans un monde où les <i>influenceurs</i> sont plus jugés à leur nombre de followers qu’à leur créativité. <br/>
            <br/>
            2/ Parce que poster des photos en pensant au nombre de likes qu’elles vont avoir plutôt qu’à l’expression personnelle n’est pas ce en quoi nous croyons. <br/>
            <br/>
            3/ Parce que nous prônons l’authenticité et l’individualité, mais aussi l'appartenance à une communauté qui nous ressemble. <br/>
            <br/>
            4/ Parce que de nombreuses marques émergentes créées par des personnes extrêmement talentueuses méritent mieux que devoir payer des Ads et rassembler des followers pendant des années pour attirer l’attention de leur communauté. <br/>
            <br/>
            5/ Parce que les marques établies cherchent encore à plaire à « la génération Y et Z » comme s’il s’agissait d’un ensemble uniforme. <br/>
            <br/>
            Nous pensons qu’il s’agit d’une multitude de communautés distinctes. <br/>
            <br/>
            <br/>
            Plaiz c’est l’ensemble de ces communautés. <br/>
            <br/>
            Et c’est là où nait <Link to={"/lefuturdelamode"} className="textChangeGame">le futur de la mode</Link>.<br/>
            <br/>
            <br/>
            <br/>
        </p>
    </h4>
    </div>
    </div>
    </div>
    </div>
)

export default Manifesto

const styles = {
  backToHomeLink: {
    color: "white",
  },
}